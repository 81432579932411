import React, { useContext, useEffect, useRef, useState } from 'react'
import Sidebar from "../../Coman/Sidebar";
import Header from "../../Coman/Header";
import Tree from 'react-d3-tree';
import axios from 'axios';
import { ProjectContext } from "../../../ProjectContext";
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

export default function TreeView() {
  const { account, copyaddress, formatAddress, copyText, getudata } = useContext(ProjectContext)

  const [data, setdata] = useState({ name: "you" });
  const [isLoading, setisLoading] = useState(false);
  const [popupdata, setpopupdata] = useState(null);
  const [popupdataloading, setpopupdataloading] = useState(false);
  const [show, setShow] = useState(false);
  const [nodetofind, setnodetofind] = useState(null);
  const { address } = useParams();
  const tooltipRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tooltipText, setTooltipText] = useState('');

  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#fff">
        {nodeData.name}
        {nodeData.uname}
      </text>
    </g>
  );
  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 1,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#32edb1"
      }
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  // Dynamically set the initial translate value based on the container size
  React.useEffect(() => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({
      x: dimensions.width / 2,
      y: dimensions.height / 4,
    });
  }, []);
  const handleNodeMouseOver = (event, nodeDatum) => {

    // Set the tooltip text based on the node data
    console.log(event);
    setTooltipText(nodeDatum.name);

    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'block';
      tooltipRef.current.style.top = `${event.clientY}px`;
      tooltipRef.current.style.left = `${event.clientX}px`;
    }
  };

  const handleNodeMouseOut = () => {
    // Clear the tooltip text
    setTooltipText('');
    // Hide the tooltip
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }

  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g >
      <foreignObject x="-150" y="-50" width="400" height="580"
        style={{ cursor: 'pointer' }}>
        <div className="tree-main text-center p-5 wow fadeIn" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn', backgroundColor: "#0e0e0e" }}>
          <div  >
            <span className='tree-span'><i className="fa fa-user" /></span>
          </div>
          <div >
            <h4 type="button" > <a className=" fs-4" type='button'>{nodeDatum.uname} </a></h4>
            <h4 type="button" > <a className=" fs-4" type='button' onClick={() => copyaddress(nodeDatum.address)}>{nodeDatum.name}  <i className='fa fa-copy'></i></a></h4>
          </div>
          {/* {nodeDatum.children?.length ? 
            <a className=" mt-4" type='button' onClick={toggleNode}><span className='tree-span'><i className="fa fa-angle-down" /></span></a>
            :
            ''} */}
          <ul className="commando-list-wrap d-flex justify-content-between align-items-end">
            {nodeDatum.children?.length ?
              <li className="commando-single-list px-2" onClick={toggleNode}>
                <div className="icon pt-1" >
                  <i className={`fa-solid fa-arrow-down`} style={{ fontSize: '27px' }}></i>
                  <span className='text-white'>Expand</span>
                </div>
              </li>
              :
              ''}
            <li className="commando-single-list px-4" onClick={() => getPopupData(nodeDatum.address)}>
              <div className="icon pt-1">
                <i className={`fa-solid fa-drivers-license`} style={{ fontSize: '23px' }}></i>
                <span className='text-white '>Details</span>
              </div>
            </li>
          </ul>
        </div>

      </foreignObject>
    </g>
  );


  // const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
  //   <g onMouseOver={(e) => handleNodeMouseOver(e, nodeDatum)} onMouseOut={handleNodeMouseOut}>
  //     {/* <rect
  //       width="120"
  //       height="50"
  //       x="-60"
  //       y="-25"
  //       onClick={toggleNode}
  //       style={{ cursor: 'pointer' }}
  //     /> */}
  //     {/* <text
  //       textAnchor="middle"
  //       alignmentBaseline="central"
  //       fontSize="12px"
  //     >
  //       {nodeDatum.name}
  //     </text> */}
  //     <foreignObject x="-130" y="-300" width="300" height="480"
  //       style={{ cursor: 'pointer' }}>
  //       {/* <div className='fortree' onClick={toggleNode}>
  //         {nodeDatum.name} <br />
  //         <span className='badge badge-success'>sss</span>
  //       </div>
  //       <button className='th-btn btn-sm' style={{ width: "50px", height: "50px" }} onClick={() => getPopupData(nodeDatum.address)}>Click</button> */}
  //       <div className="commando-card p-5  text-center" style={{ backgroundColor: "#000", borderRadius: "25px" }}>
  //         <div className="commando-card-bg-shape background-image" />
  //         <div className="">
  //           <i className={`fa-solid fa-user ${nodeDatum.status ? "text-success" : "text-white"} `} style={{ fontSize: '140px' }}></i>
  //           <h4 className="commando-card-title text-white text-break">{nodeDatum.name}</h4>
  //         </div>
  //         <ul className="commando-list-wrap d-flex justify-content-between align-items-end">
  //           {nodeDatum.children?.length ?
  //             <li className="commando-single-list px-2" onClick={toggleNode}>
  //               <div className="icon pt-1" >
  //                 <i className={`fa-solid fa-arrow-down`} style={{ fontSize: '27px' }}></i>
  //                 <span className='text-white'>Expand</span>
  //               </div>
  //             </li>
  //             :
  //             ''}
  //           <li className="commando-single-list px-4" onClick={() => getPopupData(nodeDatum.address)}>
  //             <div className="icon pt-1">
  //               <i className={`fa-solid fa-drivers-license`} style={{ fontSize: '23px' }}></i>
  //               <span className='text-white '>Details</span>
  //             </div>
  //           </li>
  //         </ul>
  //       </div>
  //     </foreignObject>
  //     {/* <foreignObject width="100" height="100" x="-50" y="-50" onClick={toggleNode}>
  //       <div className="custom-node">
  //         <h3>{nodeDatum.name}</h3>
  //       </div>
  //     </foreignObject> */}

  //   </g>
  // );
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        // address: "0x8c4a69ea09077020c97372078e867b148dd9406b",
        address: account,
        // address: "0xe7b485d74aafb65e8b8135dbcb226bdcdf414877",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        console.log("getdat", res.data.data);

        setdata(res.data.data);
      });
  };
  const getPopupData = async (address) => {
    setShow(true)
    setpopupdataloading(true)
    var data = await getudata(address);
    if (data.data.error) {
      setpopupdata(null);
      setpopupdataloading(false)
      return "";
    }
    setpopupdata(data.data.data);
    console.log("popup data", data.data.data);
    setpopupdataloading(false)
  }
  useEffect(() => {
    getData();
  }, [account]);

  return (
    <div>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper" id="treeWrapper">
                <Sidebar />
                <Header />
                <div className="main-chat">
                  {isLoading ?
                    <div className='text-center'><h2>Loading...</h2></div>
                    :
                    <Tree
                      data={data}
                      orientation="vertical"
                      {...treeConfig}
                      scaleExtent={{ min: 0.1, max: 1 }}
                      isInitialRenderForDataset={true}
                      enableLegacyTransitions={true}
                      renderCustomNodeElement={renderRectSvgNode}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div className='d-flex justify-content-center text-dark'>
          <div class="modal-content ">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="modal-title" id="exampleModalLabel">Details</h5>
              <button type="button" className="btn-sm btn-sucess" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <Modal.Body>
              <div className="row ">
                {popupdataloading ? "Loading..." :
                  <>
                    {popupdata ?
                      <>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Username</span> <span class="text-break text-end">{popupdata.uname}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Bot Amount</span> <span class="text-break text-end">$ {popupdata.t_staking}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Trade Fund</span> <span class="text-break text-end">$ {popupdata.t_staking_s}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Team Volume</span> <span class="text-break text-end">$ {popupdata.team_business} {popupdata.direct_team_business_staking}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Date of Joining</span> <span class="text-break text-end">{popupdata.createdAt}</span></div>

                        {/* <div className=" style-border2 col-md-12 d-flex justify-content-between">
                          <span>Rank</span> <span>{popupdata.rank ? popupdata.rank : 'No Rank'}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Referral ID</span> <span class="text-break text-end" onClick={() => copyText(popupdata.user_id)}>{popupdata.user_id} <i className='fa fa-copy'></i></span></div> */}

                        {/* <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Address: </span> <span class="text-break text-end " onClick={() => copyText(popupdata.address)}>{formatAddress(popupdata.address)} <i className='fa fa-copy'></i></span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Direct Business</span> <span class="text-break text-end">$ {popupdata.direct_team_business}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Team Business</span> <span class="text-break text-end">$ {popupdata.team_business}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Directs</span> <span class="text-break text-end">{popupdata.d_team}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Team</span> <span class="text-break text-end">{popupdata.team}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Self Investment</span> <span class="text-break text-end">{popupdata.t_staking}</span></div> */}
                      </> : ''}
                  </>}
              </div>
            </Modal.Body>
          </div>

        </div>
      </Modal>
    </div>
  );
}
