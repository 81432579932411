import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";

export default function Sidebar() {
  const { disconnectNow } = useContext(ProjectContext);

  const [subMenu, setSubMenu] = useState(false);
  const currentPath = window.location.pathname;
  var navigate = useNavigate();

  const logout = () => {
    disconnectNow();
    navigate("/");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-md p-0">
        <button
          className="navbar-toggler d-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainnavbarNav"
          aria-controls="mainnavbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="iconsax" data-icon="text-align-justify" />
        </button>
        <div className="collapse navbar-collapse" id="mainnavbarNav">
          <div className="menu-panel" style={{overflow:"auto"}}>
            <button
              data-bs-toggle="collapse"
              data-bs-target="#mainnavbarNav"
              className="mainnav-close d-block d-md-none"
            >
              <i className="iconsax" data-icon="close-circle" />
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>

            <ul className="nav nav-tabs menu-wrapper" id="myTab" role="tablist">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  onClick={() => setSubMenu(!subMenu)}
                  // className="nav-link active"
                  className={`${currentPath === "/dashboard" || currentPath === "/dashboard"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Dashboard</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/purchase-bot"
                  // href="#"
                  className={`${currentPath === "/purchase-bot" || currentPath === "/purchase-bot"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Purchase Bot</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/purchase-history"
                  // href="#"
                  className={`${currentPath === "/purchase-history" ||
                    currentPath === "/purchase-history"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Purchase History</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/addliquidity"
                  // to="#"
                  className={`${currentPath === "/addliquidity" ||
                    currentPath === "/addliquidity"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Add Liquidity</span>{" "}
                </Link>
              </li>


              <li className="nav-item">
                <Link
                  to="/liquidity-history"
                  // href="#"
                  className={`${currentPath === "/liquidity-history" ||
                    currentPath === "/liquidity-history"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Liquidity History</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  // href="/team"
                  to="/tree"
                  className={`${currentPath === "/tree" || currentPath === "/tree"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Tree</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/team"
                  // href="#"
                  className={`${currentPath === "/team" || currentPath === "/team"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Team</span>{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/staking-rewards"
                  // href="#"
                  className={`${currentPath === "/staking-rewards" ||
                    currentPath === "/staking-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Staking Rewards</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/sponsor-rewards"
                  // href="#"
                  className={`${currentPath === "/sponsor-rewards" ||
                    currentPath === "/sponsor-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Sponsor Rewards</span>{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/mobilization-rewards"
                  // href="#"
                  className={`${currentPath === "/mobilization-rewards" ||
                    currentPath === "/mobilization-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Mobilization Rewards</span>{" "}
                </Link>
              </li>
              {/* <li className="nav-item">
                <a href="#" className="nav-link ">
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Bonus</span>{" "}
                </a>
              </li> */}
              <li className="nav-item">
                <Link
                  to="/salary-rewards"
                  // href="#"
                  className={`${currentPath === "/salary-rewards" ||
                    currentPath === "/salary-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Salary Rewards</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/activity-rewards"
                  // href="#"
                  className={`${currentPath === "/activity-rewards" ||
                    currentPath === "/activity-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Activity Rewards</span>{" "}
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  to="/royalty-rewards"
                  // href="#"
                  className={`${currentPath === "/royalty-rewards" ||
                    currentPath === "/royalty-rewards"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Royalty Rewards</span>{" "}
                </Link>
              </li>
              <li className="nav-item" role="withdrawal">
                <Link
                  to="/withdrawal"
                  // href="#"
                  className={`${currentPath === "/withdrawal" ||
                    currentPath === "/withdrawal"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Withdrawal</span>{" "}
                </Link>
              </li>
              <li className="nav-item" role="profile">
                <Link
                  to="/profile"
                  // href="#"
                  className={`${currentPath === "/profile" ||
                    currentPath === "/profile"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Profile</span>{" "}
                </Link>
              </li>

              <li className="nav-item" role="presentation">
                <Link
                  to="/assets/presentation.pdf"
                  target="_blank"
                  className={`${currentPath === "#" || currentPath === "#"
                    ? "nav-link active"
                    : "nav-link "
                    }`}
                >
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Presentation</span>{" "}
                </Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link " onClick={() => logout()}>
                  <i className="iconsax" data-icon="messages-2" />
                  <span>Logout</span>{" "}
                </a>
              </li>
              {/*  */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="m">
        <nav className="navbar navbar-expand-lg bg-light p-0">
          <button
            className="navbar-toggler d-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="inner-menu-panel">
              <button
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                className="inner-close d-block d-lg-none"
              >
                Back
              </button>
              <Link to="/">
                <img
                  src="assets/images/img/logo.svg"
                  class="img-fluid"
                  alt="logo"
                  style={{ width: "200px" }}
                />
              </Link>
              <ul className="inner-links-list" id="innerLink">
                <li
                  className={`${currentPath === "/dashboard" || currentPath === "/dashboard"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link
                    to="/dashboard"
                    data-title="Ask anything"
                    onClick={() => setSubMenu(!subMenu)}
                  >
                    Dashboard
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/purchase-bot" || currentPath === "/purchase-bot"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/purchase-bot" data-title="Image generator">
                    {/* <Link to="#" data-title="Image generator"> */}
                    Purchase Bot
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/addliquidity" ||
                    currentPath === "/addliquidity"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/addliquidity" data-title="Content writer">
                    {/* <Link to="#" data-title="Content writer"> */}
                    Add Liquidity
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/purchase-history" ||
                    currentPath === "/purchase-history"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/purchase-history" data-title="Content writer">
                    {/* <Link to="#" data-title="Content writer"> */}
                    Purchase History
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/liquidity-history" ||
                    currentPath === "/liquidity-history"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/liquidity-history" data-title="Content writer">
                    {/* <Link to="#" data-title="Content writer"> */}
                    Liquidity History
                  </Link>
                </li>

                <li
                  li
                  className={`${currentPath === "/tree" || currentPath === "/tree"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  {/* <Link to="/team" data-title="Code generator"> */}
                  <Link to="/tree" data-title="Code generator">
                    Tree
                  </Link>
                </li>
                <li
                  li
                  className={`${currentPath === "/team" || currentPath === "/team"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/team" data-title="Code generator">
                    {/* <Link to="#" data-title="Code generator"> */}
                    Team
                  </Link>
                </li>
                <li
                  li
                  className={`${currentPath === "/staking-rewards" ||
                    currentPath === "/staking-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/staking-rewards" data-title="Code generator">
                    {/* <Link to="#" data-title="Code generator"> */}
                    Staking Rewards
                  </Link>
                </li>
                <li
                  li
                  className={`${currentPath === "/sponsor-rewards" ||
                    currentPath === "/sponsor-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/sponsor-rewards" data-title="Code generator">
                    {/* <Link to="#" data-title="Code generator"> */}
                    Sponsor Rewards
                  </Link>
                </li>

                <li
                  className={`${currentPath === "/mobilization-rewards" ||
                    currentPath === "/mobilization-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/mobilization-rewards" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Mobilization Rewards
                  </Link>
                </li>

                <li
                  className={`${currentPath === "/salary-rewards" ||
                    currentPath === "/salary-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/salary-rewards" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Salary Rewards
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/activity-rewards" ||
                    currentPath === "/activity-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/activity-rewards" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Activity Rewards
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/royalty-rewards" ||
                    currentPath === "/royalty-rewards"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/royalty-rewards" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Royalty Rewards
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/withdrawal" ||
                    currentPath === "/withdrawal"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/withdrawal" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Withdrawal
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/profile" ||
                    currentPath === "/profile"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/profile" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Profile
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/unstake" ||
                    currentPath === "/unstake"
                    ? "active step-3"
                    : "step-2"
                    }`}
                >
                  <Link to="/unstake" data-title="Social media">
                    {/* <Link to="#" data-title="Social media"> */}
                    Unstake
                  </Link>
                </li>

                <li className="step-2">
                  <a href="/assets/presentation.pdf"
                    target="_blank" data-title="presentation">
                    Presentation
                  </a>
                </li>
                <li className="step-2">
                  <a href="#" data-title="Essay writer" onClick={() => logout()}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
