import React from 'react'
import Sidebar from '../../Coman/Sidebar'
import Header from '../../Coman/Header'
import Unstake from './Unstake'

export default function UnstakeIndex() {
  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img">
                <div className="">

                  <Unstake />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
