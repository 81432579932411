import React, { useContext } from "react";
import CountdownTimer from "../Coman/CountdownTimer";
import { ProjectContext } from "../../ProjectContext";

export default function Body() {
  const {
    account,
    dbuser,
    dashD,
    copyaddress,
    formatAddress,
  } = useContext(ProjectContext);
  return (
    <div>
      <div>
        {/* <h2 className="text-white mb-5 text-center mt-3">Purchase Bot </h2> */}
        <div className="contact-details">
          <div className="col-lg-12 mt-3"></div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="step-1 py-5">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="step-text text-white  mt-3"
                  style={{ position: "relative", zIndex: "999" }}
                >
                  <h5 onClick={() => copyaddress(account)}>
                    My Account : {formatAddress(account)}
                    <i
                      className="fa-solid fa-copy ms-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </h5>
                  <h5 onClick={() => copyaddress(dbuser ? dbuser.ref_address : '')}>
                    Referral Address : {dbuser !== null ? formatAddress(dbuser.ref_address) : "-"}{" "}
                    <i
                      className="fa-solid fa-copy ms-2 "
                      style={{ cursor: "pointer" }}
                    ></i>
                  </h5>
                </div>
                <div
                  className="step-text text-white mt-3"
                  style={{ position: "relative", zIndex: "999" }}
                >
                  {dbuser ?
                    <a
                      data-cursor="pointer"
                      href="#"
                      className="btn btn-theme btn-sm w-50 "
                      onClick={() => copyaddress(`${process.env.REACT_APP_LINK}login?referral=${account}`)}
                    >
                      Click to copy referral link{" "}
                      <i className="fa-solid fa-copy ms-2 "
                        style={{ cursor: "pointer" }}
                      ></i>
                    </a>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-white   mt-5">Rewards</h3>
          <div className="row ">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.level_inc : '0'}</div>
                <div className="step-text text-white mt-3">Sponsor Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.level_inc_pending : '0'}</div>
                <div className="step-text text-white mt-3">Pending Sponsor Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.tfm_inc : '0'}</div>
                <div className="step-text text-white mt-3">
                  Mobilization Rewards
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.salary_inc : '0'}</div>
                <div className="step-text text-white mt-3">Salary Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.activity_club_inc : '0'}</div>
                <div className="step-text text-white mt-3">Activity Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">$0</div>
                <div className="step-text text-white mt-3">Royalty Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.staking_inc : '0'}</div>
                <div className="step-text text-white mt-3">Staking Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dbuser ? dbuser.club ? dbuser.club : 'Not Available' : 'Not Available'}</div>
                <div className="step-text text-white mt-3">Activity Club</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dbuser ? dbuser.royalty ? 'Acheived' : 'Not Achieved' : "-"}</div>
                <div className="step-text text-white mt-3">Royalty</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-white mt-5">Salary Achievement</h3>
          <div className="row ">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">
                  {dbuser ? dbuser.salary_income >= 100 ? 'Achieved' :
                    <CountdownTimer targetDate={dbuser ? dbuser.createdAt_7 : null} /> : "-"}
                </div>
                <div className="step-text text-white mt-3">$100 Salary</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">
                  {dbuser ? dbuser.salary_income >= 250 ? 'Achieved' :
                    <CountdownTimer targetDate={dbuser ? dbuser.createdAt_15 : null} /> : "-"}
                </div>
                <div className="step-text text-white mt-3">$250 Salary</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">
                  {dbuser ? dbuser.salary_income >= 500 ? 'Achieved' :
                    <CountdownTimer targetDate={dbuser ? dbuser.createdAt_21 : null} /> : "-"}
                </div>
                <div className="step-text text-white mt-3">$500 Salary</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-white mt-5">Statistics</h3>
          <div className="row ">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                {/* <div className="step-number-1">${dbuser ? dbuser.t_staking : '0'}</div> */}
                <div className="step-number-1">${dbuser ? dbuser.c_staking : '0'}</div>
                <div className="step-text text-white mt-3">Bot</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.t_staking_s : '0'}</div>
                <div className="step-text text-white mt-3">Liquidity</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dbuser ? dbuser.open_level : '0'}</div>
                <div className="step-text text-white mt-3">Active Level</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dbuser ? dbuser.team : '0'}</div>
                <div className="step-text text-white mt-3">Team</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dbuser ? dbuser.d_team : '0'}</div>
                <div className="step-text text-white mt-3">Direct Team</div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">-</div>
                <div className="step-text text-white mt-3">Next Club</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.team_business_staking : '0'}</div>
                <div className="step-text text-white mt-3">Team Business</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.direct_team_business_staking : '0'}</div>
                <div className="step-text text-white mt-3">Direct Team Business</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">$0</div>
                <div className="step-text text-white mt-3"> Sales Team </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">$0</div>
                <div className="step-text text-white mt-3">Other Sales Team </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">$0</div>
                <div className="step-text text-white mt-3">Pending Withdrawal </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">$ {dbuser ? dbuser.t_withdrawal : '0'}</div>
                <div className="step-text text-white mt-3">Total Withdrawal </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">{dashD ? dashD.t_bot : '0'}</div>
                <div className="step-text text-white mt-3">Total Bot Count </div>
              </div>
            </div> */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.royalty_r_strong : '0'}</div>
                <div className="step-text text-white mt-3">Required Strong </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div className="step-1 py-4">
                <div className="step-number-1">${dbuser ? dbuser.royalty_r_other : '0'}</div>
                <div className="step-text text-white mt-3">Required Other </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
